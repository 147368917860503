<template>
  <van-nav-bar
    :title="title"
    right-text="创建"
    @click-right="onOpenForm(null)"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 7px 0;">
          <small>网点名称 {{ item.name }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>电话 {{ item.tel }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>地址 {{ item.address }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <van-tag :type="item.longitude ? 'success' : 'danger'">{{ item.longitude ? '已标注位置' : '未标注位置' }}</van-tag>
        </p>
        <van-button @click="onOpenForm(item)">修改</van-button>&nbsp;
        <van-button :to="{ name: 'OutletPosition', query: { outletId: item.outletId } }">标注位置</van-button>
      </div>
    </template>
  </van-list>
  <van-popup v-model:show="form.show" position="bottom">
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.row.name"
        name="name"
        label="网点名称"
        placeholder=""
      />
      <van-field
        v-model="form.row.tel"
        name="tel"
        label="店内电话"
        placeholder=""
      />
      <van-field
        v-model="form.row.address"
        name="address"
        label="街道地址"
        placeholder=""
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
        <template v-if="(typeof form.row.outletId !== 'undefined')">
          <van-button round block style="margin:15px 0 0 0;" @click="onDelete(form.row.outletId)">删除</van-button>
        </template>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      form: {
        show: false,
        row: {}
      },
      row: {
      },
      outlets: [],
      storeId: Cookies.get('storeId')
    })
    const onOpenForm = (item) => {
      state.form.show = true
      if (item !== null) {
        // state.form.row = item
        state.form.row = Object.assign({}, item)
      } else {
        state.form.row = {}
      }
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const onSubmit = (values) => {
      if (typeof state.form.row.outletId === 'undefined') {
        post('/outlet.create', {
          ...values,
          storeId: state.storeId,
          longitude: 0,
          latitude: 0
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      } else {
        post('/outlet.update', {
          ...values,
          outletId: state.form.row.outletId,
          longitude: state.form.row.longitude,
          latitude: state.form.row.latitude
        }).then(res => {
          console.log(res)
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onDelete = (id) => {
      Dialog.confirm({
        title: '提示',
        message: '操作确认'
      }).then(() => {
        post('/outlet.delete', {
          outletIds: id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const getData = () => {
      post('/outlet.list', {
        pageNum: state.page,
        storeId: state.storeId
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 个线下网点'
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onOpenForm,
      onSubmit,
      onDelete
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
